/* eslint-disable react/no-unknown-property */
'use client'
import React from 'react'
import styles from '../../index.module.scss'
import Image from 'next/image'
import { useRouter } from 'next/navigation'
import common_styles from 'app/index.module.scss'
import { setCookie } from 'helpers/cookies'
import ShowSalary from 'app/components/commons/NewShowSalary'
import {
  setJobViewCookiesTracker,
  viewJobSourceTracker
} from 'components/ReportTracker/util'

const JobCard = (props: any) => {
  const {
    job_title,
    job_id: id,
    job_url,
    is_urgent,
    job_tags: labels,
    cardType,
    is_remote_job,
    remote_job_text,
    urgent_text,
    salary_info,
    hideCompanyTag = false,
    location
  } = props || {};

  const {
    avatar: recruiter_avatar,
    full_name: recruiter_full_name,
    job_title: recruiter_job_title,
    is_online: recruiter_is_online
  } = props.recruiter || {};

  const {
    company_name,
    company_tags
  } = props.company || {};

  const router = useRouter()

  return (
    <div className={styles.jobCardMoblie}>
      <div
        id={'job_card_container_' + id}
        className={styles.container}
        onClick={(e) => {
          e.stopPropagation()
          setJobViewCookiesTracker(setCookie, {
            source: viewJobSourceTracker.jobsHiring,
            reco_from: cardType === 'hotjobs' ? props?.reco_from : null,
            query_id: props?.query_id || null
          })
          router.push(job_url, { scroll: true })
        }}
      >
        <div className={`${styles.titleContainer} ${styles.titleContainerUrgent}`}>
          {is_urgent ? <span className={styles.urgent}>{urgent_text}</span> : null}{' '}
          {is_remote_job ? (
            <span className={styles.urgent + ' ' + styles.remote}>{remote_job_text}</span>
          ) : null}
        </div>

        <div key={job_title + id} className={styles.titleContainer} title={`${job_title}`}>
          <div className={styles.title}>{`${job_title}`}</div>
          <div className={styles.salary}>
            <ShowSalary salary_info={salary_info} size={14} />
          </div>
        </div>

        <div className={styles.companyInfo}>
          <p className={styles.companyName}>{company_name}</p>
          {/* {company_tags?.[0] && hideCompanyTag ? (
            <>
              <span className={styles.companyInfoSpread}></span>
              <span className={styles.companySize}>{company_tags?.[0]}</span>
            </>
          ) : null} */}
        </div>
        <div className={styles.labelContainer}>
          {labels.map((label) => (
            <div key={label} className={styles.label}>
              {label}
            </div>
          ))}
        </div>

        {/* line */}
        <div className={styles.recruiterLine}></div>

        <div className={styles.recruiterContainer}>
          <div className={styles.info}>
            <div className={`${common_styles.avator}  ${recruiter_is_online ? '' : common_styles.avator2}`} >
              <Image src={recruiter_avatar} height={24} width={24} alt={''} />
            </div>
            <div
              className={styles.hrTitle}
              title={`${[recruiter_full_name, recruiter_job_title].filter((a) => a).join(' · ')}`}
            >
              {`${[recruiter_full_name, recruiter_job_title].filter((a) => a).join(' · ')}`}
            </div>
          </div>
          <div className={styles.fullName}>
            {location}
          </div>
        </div>
      </div>
    </div>
  )
}

export default JobCard
